import { SideModal } from 'app/components/Modals/SideModal';
import { Button, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

export const LeaveSiteModal = ({ onClose, onConfirm, open }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <SideModal
      onClose={onClose}
      onClick={onConfirm}
      open={open}
      title={t('leaveSiteTitle')}
      confirmButtonText={t('confirm')}
      buttons={
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {t('cancel')}
        </Button>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', color: 'comet' }}>
          {t('leaveSiteDescription')}
        </Typography>
        <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
          {t('leaveSiteConfirmation')}
        </Typography>
      </Box>
    </SideModal>
  );
};
