import { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import moment from 'moment';
import notesActions from 'redux/actions/notes';
import { useTranslation } from 'react-i18next';
import NotesDeleteModal from '../NotesDeleteModal';
import { NewNoteParams } from 'types/notes';
import { MuiDatePicker } from 'app/components/MuiDatePicker';
import { useStatus } from 'hooks/useStatus';
import '../Notes.scss';
import { ProgressOverlay } from 'app/components/Overlays/ProgressOverlay';
import { useForm, Controller } from 'react-hook-form';
import { SideModal } from 'app/components/Modals/SideModal';
import { notesButtonColorOptions, notesFormFieldNames } from 'app/components/Notes/_config';
import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { MuiTextarea } from 'app/components/FormElements/MuiTextarea';
import { ButtonsWithColors } from 'app/components/ButtonsWithColors';
import { DatesMoment } from 'enums/dates';
import { TagSelector } from 'app/components/FormElements/TagSelector';
import { StyledTypography } from 'app/components/TextElements/StyledTypography';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const NotesDrawer = () => {
  const dispatch = useAppDispatch();
  const { isReadOnly } = useStatus();
  const { t } = useTranslation();

  const current_date = useAppSelector((state) => state.app.app.current_date);
  const notes = useAppSelector((state) => state.notes);
  const timeAndLanguage = useAppSelector((state) => state.app.settings.time_and_language);
  const dateFormat = DatesMoment[timeAndLanguage.date_format];

  const selectedDate = moment.unix(current_date).startOf('day');

  const defaultValues = {
    [notesFormFieldNames.color]: notesButtonColorOptions[0].value,
    [notesFormFieldNames.start_date]: selectedDate,
    [notesFormFieldNames.end_date]: selectedDate,
    [notesFormFieldNames.days]: [],
    [notesFormFieldNames.name]: '',
    [notesFormFieldNames.text]: '',
  };

  const methods = useForm({
    mode: 'onTouched',
    defaultValues,
  });

  const { handleSubmit, reset, watch } = methods;
  const selectedNoteId = notes.selectedNote?.id;

  const onSubmit = ({ start_date, end_date, days, color, name, text }: any) => {
    const note: NewNoteParams = {
      date: {
        startDate: start_date.format(DatesMoment['ymd']),
        endDate: end_date.format(DatesMoment['ymd']),
      },
      days: days.filter((day: number | string) => !weekdayDisabledCheck(day)),
      color,
      name,
      text,
    };
    if (selectedNoteId) {
      dispatch(notesActions.updateNote(selectedNoteId, note)).then(() => onModalClose());
    } else {
      dispatch(notesActions.createNote(note)).then(() => onModalClose());
    }
  };

  const startDate: any = watch('start_date');
  const endDate: any = watch('end_date');

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const isValidStartDate = startDate <= endDate;

  const weekdays = Array(7)
    .fill('')
    .map((_, i) => {
      const date = moment().day((i + 1) % 7);
      return { label: date.format('dddd'), value: date.day() };
    });

  const availableWeekdays =
    endDate.diff(startDate, 'days') > -1
      ? Array(endDate.diff(startDate, 'days') + 1)
          .fill('')
          .reduce((acc, _, i) => {
            const dayNumber = moment(startDate).add(i, 'days').day();
            return !acc.includes(dayNumber) ? [...acc, dayNumber] : acc;
          }, [])
      : [];

  const weekdayDisabledCheck = (dayNumber: number | string) =>
    availableWeekdays.length < 2 || !availableWeekdays.includes(dayNumber);

  const handleDeleteNote = () => {
    if (!notes.selectedNote) return;
    setDeleteDialogOpen(true);
  };

  const onReset = () => {
    dispatch(notesActions.setSelectedNote(null));
    reset(defaultValues);
  };

  const onModalClose = () => {
    dispatch(notesActions.setIsDrawerOpen(false));
    onReset();
  };

  useEffect(() => {
    if (selectedNoteId) {
      const { start_date, end_date, days, color, name, text } = notes.selectedNote || {};
      const note: any = {
        start_date: moment(start_date).startOf('day'),
        end_date: moment(end_date).startOf('day'),
        days,
        color,
        name,
        text,
      };
      reset(note);
    } else {
      onReset();
    }
  }, [selectedNoteId, current_date]);

  return (
    <SideModal
      title={t('noteModalTitle')}
      handleSubmit={handleSubmit}
      methods={methods}
      onClose={onModalClose}
      onSubmit={onSubmit}
      open={notes.isDrawerOpen}
      saveDisabled={!isValidStartDate || isReadOnly}
      buttons={
        selectedNoteId ? (
          <Button disabled={isReadOnly} onClick={handleDeleteNote} variant="outlined" color="error">
            {t('deleteNote')}
          </Button>
        ) : null
      }
    >
      <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <StyledTypography sx={{ marginBottom: '8px' }}>{t('noteSelectTime')}</StyledTypography>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Controller
              name={notesFormFieldNames.start_date}
              control={methods.control}
              render={({ field }) => (
                <MuiDatePicker
                  fullWidth
                  label={t('noteStartDate')}
                  disabled={isReadOnly}
                  format={timeAndLanguage.date_format}
                  value={field.value ? moment(field.value, dateFormat) : null}
                  onChange={(date) => {
                    field.onChange(date ? date : '');
                  }}
                />
              )}
            />
            <Controller
              name={notesFormFieldNames.end_date}
              control={methods.control}
              render={({ field }) => (
                <MuiDatePicker
                  fullWidth
                  label={t('noteEndDate')}
                  disabled={isReadOnly}
                  format={timeAndLanguage.date_format}
                  value={field.value ? moment(field.value, dateFormat) : null}
                  onChange={(date) => {
                    field.onChange(date ? date : '');
                  }}
                  shouldDisableDate={(date) =>
                    date?.isBefore(methods.getValues(notesFormFieldNames.start_date)) || false
                  }
                />
              )}
            />
          </Box>
        </Box>
        <TagSelector
          name={notesFormFieldNames.days}
          label={t('noteRepeat')}
          tags={weekdays}
          disabledCheck={weekdayDisabledCheck}
        />

        <ButtonsWithColors name={notesFormFieldNames.color} options={notesButtonColorOptions} />
        <ControlledInput
          label={t('noteName')}
          disabled={isReadOnly}
          name={notesFormFieldNames.name}
        />
        <MuiTextarea
          label={t('noteMessage')}
          disabled={isReadOnly}
          name={notesFormFieldNames.text}
        />
        <ProgressOverlay isLoader open={notes.createNoteLoading} />
      </Box>
      {!!notes.selectedNote && (
        <NotesDeleteModal
          isOpen={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          note={notes.selectedNote}
          onReset={() => onReset()}
        />
      )}
    </SideModal>
  );
};

export default NotesDrawer;
