import { ReactElement, useState } from 'react';
import { Select, MenuItem, Box, Typography, FormControl, SxProps } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';
import { useTranslation } from 'react-i18next';
import { CustomLabel } from 'app/components/CustomLabel';
import { RotatingArrow } from 'app/components/StyledComponents/RotatingArrow';
import { getLabelForValue } from 'utils/data-processors/getLabelForValue';

export interface SelectOption {
  label: string;
  value?: string | number;
  disabled?: boolean;
}

type Props = {
  name: string;
  options: SelectOption[];
  customOnChange?: (value: SelectChangeEvent<HTMLInputElement>) => void;
  variant?: string;
  icon?: ReactElement;
  placeholder?: string | null;
  label?: string | null;
  defaultLabel?: string | null;
  emptySelectInformation?: string;
  nullable?: boolean;
  description?: string | null;
  sx?: SxProps;
  translateLabels?: boolean;
  disabled?: boolean;
  shouldValidate?: boolean;
};

export const MuiSelect = ({
  name,
  options,
  customOnChange,
  icon,
  label,
  defaultLabel,
  placeholder,
  emptySelectInformation,
  nullable = false,
  description,
  sx,
  translateLabels = true,
  disabled = false,
  shouldValidate = false,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { control } = useFormContext();

  const {
    field,
    formState: { errors },
  } = useController({
    name,
    control,
  });

  const handleOnChange = (e: SelectChangeEvent<HTMLInputElement>) => {
    if (customOnChange) {
      customOnChange(e);
    }
    field.onChange(e, { shouldValidate });
  };

  const optionsToRender = options.length ? (
    options.map(({ value, label }, index) => (
      <MenuItem key={index} value={value}>
        {translateLabels ? t(label) : label}
      </MenuItem>
    ))
  ) : (
    <MenuItem value="">{emptySelectInformation || t('selectionOptionsAreUnavailable')}</MenuItem>
  );

  return (
    <FormControl sx={{ display: 'flex', flexDirection: 'column', flex: 1, ...sx }}>
      <CustomLabel
        label={label}
        name={name}
        description={description}
        defaultLabel={defaultLabel}
      />
      <Select
        fullWidth
        displayEmpty={true}
        {...field}
        label={defaultLabel}
        onChange={handleOnChange}
        IconComponent={() => null}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        disabled={disabled}
        error={!!errors[name]}
        sx={{
          '& .MuiSelect-select': {
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingRight: '42px !important',
            display: 'flex',
            alignItems: 'center',
          },
        }}
        renderValue={(value: any) => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {icon && <Box sx={{ width: '16px', height: '16px', display: 'flex' }}>{icon}</Box>}
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {getLabelForValue(value, options, translateLabels, t) || placeholder}
              </Typography>
              <RotatingArrow
                open={isOpen}
                sx={{
                  position: 'absolute',
                  top: '8px',
                  right: '12px',
                }}
                isMargin={false}
              />
            </Box>
          );
        }}
      >
        {nullable && <MenuItem value="">- none -</MenuItem>}
        {optionsToRender}
      </Select>
      <CustomErrorMessage errors={errors} name={name} />
    </FormControl>
  );
};
