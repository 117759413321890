import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LinkText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  wordBreak: 'break-word',
  color: theme.palette.comet,
}));

export default LinkText;
