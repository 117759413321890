import { Pagination as MuiPagination, PaginationProps } from '@mui/material';
import { PaginatedResponse } from 'types/app/paginatedResponse';
import { ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props extends PaginationProps {
  data: PaginatedResponse<unknown>;
}

const Pagination = (props: Props) => {
  const { data, ...rest } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange = (_: ChangeEvent<unknown>, page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  };

  return (
    <MuiPagination
      onChange={handlePageChange}
      page={data.current_page}
      count={data.last_page}
      {...rest}
    />
  );
};

export default Pagination;
