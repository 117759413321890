import { styled } from '@mui/material/styles';
import { ButtonBase } from '@mui/material';

type Props = {
  disabled?: boolean;
};

const MenuLink = styled(ButtonBase)<Props>(({ theme, disabled }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.6),
  color: theme.palette.accent.gray,
  '& svg path': {
    stroke: theme.palette.navyBlue,
  },
  '&:hover': {
    textDecoration: 'none',
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.03)',
  },
  ['@media (min-width: 760px)']: {
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      '& p': {
        color: theme.palette.white,
      },
      '& svg path': {
        stroke: theme.palette.white,
      },
    },
  },
  ...(disabled && {
    color: theme.palette.disabled2,
    '& svg path': {
      stroke: theme.palette.disabled2,
    },
    '&:hover': {
      color: theme.palette.disabled2,
      cursor: 'not-allowed',
      textDecoration: 'none',
      '.sub-nav-link': {},
      '& svg path': {
        stroke: theme.palette.disabled2,
      },
    },
  }),
})) as typeof ButtonBase;

export default MenuLink;
