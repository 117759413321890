import { Box, Autocomplete, TextField, IconButton } from '@mui/material';
import { ReactComponent as SearchIcon } from 'images/icons/ic-search-v2.svg';
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { ReactComponent as CloseIcon } from 'images/icons/ic-x-circle.svg';
import { Client } from 'types/app/clients';
import { Option } from 'types/admin/Option';

interface OwnProps {
  value: string;
  options: Option<Client>[];
  onInputChange: (value: string) => void;
  onOptionSelected: (client: Client) => void;
  isLoading?: boolean;
  disabled?: boolean;
}

export const SearchAutocomplete = ({
  value,
  options,
  onOptionSelected,
  onInputChange,
  isLoading,
  disabled,
}: OwnProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Autocomplete
        freeSolo
        disabled={disabled}
        options={options}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
        inputValue={value}
        disableClearable={true}
        onInputChange={(_, newInputValue) => {
          onInputChange(newInputValue);
        }}
        onChange={(_, newValue) => {
          if (newValue && typeof newValue !== 'string') {
            onOptionSelected(newValue.value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon color={theme.palette.navyBlue} />,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {value.length > 0 && !isLoading && (
                    <IconButton
                      onClick={() => onInputChange('')}
                      sx={{ padding: 0, color: 'comet' }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              '& .MuiInputBase-root': {
                padding: '0 9px',
              },
              input: {
                paddingTop: '10px !important',
                paddingBottom: '10px !important',
              },
            }}
          />
        )}
      />
    </Box>
  );
};
