import { SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface OwnProps {
  children: ReactNode;
  sx?: SxProps;
}

export const Heading = ({ children, sx }: OwnProps) => {
  return (
    <Typography
      sx={{
        fontSize: '14px',
        fontWeight: 600,
        marginBottom: 1,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
